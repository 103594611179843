import logo from '../res/images/logo.svg'

const Header = () => {
    return (
        <header>
            <div className="container">
                <div className="logo">
                    <img src={logo} />
                </div>
            </div>
        </header>
    )
}

export default Header