import React, { useState, useEffect } from 'react';
import { auth } from '../app/firebase.js'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../app/firebase.js';

const Register = () => {
    const [error, setError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        const { 
            email, 
            password, 
            companyName, 
            companyAddress, 
            companyPhone, 
            companyEIK, 
            companyDDSNo,
            companyMol,
            companyCity
         } = e.target.elements
        try {
            await createUserWithEmailAndPassword(auth, email.value, password.value).then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                // add data to local storage
                localStorage.setItem('user', JSON.stringify(auth.currentUser))

                // create collection in firestore companies 
                const data = {
                    companyName: companyName.value,
                    companyAddress: companyAddress.value,
                    companyPhone: companyPhone.value,
                    companyEIK: companyEIK.value,
                    companyDDSNo: companyDDSNo.value,
                    companyMol: companyMol.value,
                    companyCity: companyCity.value,
                    userId: userCredential.user.uid,
                }

                addDoc(collection(db, 'companies'), data);
                // redirect to /
                navigate('/');
            }).catch ((error) => {
                setError('Error signing in with password and email!')
                console.log(error)
            })

            setLoading(false)
        } catch (error) {
            console.error(error)
            // Handle error (e.g., display an error message)
            setError('Error signing in with password and email!')
            setLoading(false)
        }
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            //navigate('/dashboard', { replace: true });
            navigate('/dashboard');
          }
        });
        return () => {
          unsubscribe(); // Cleanup the listener when the component unmounts
        };
      }, [navigate]);

    return (
        <div>
            <h1>
                За да се регистрирате, моля попълнете полетата!
            </h1>
            <form onSubmit={handleSubmit} className='form'>
                <div className='form-input'>
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" placeholder="Email" />
                </div>
                <div className='form-input'>
                    <label htmlFor="password">Парола</label>
                    <input type="password" name="password" placeholder="Парола" />
                </div>
                <div className='form-input'>
                    <label htmlFor="companyName">Име на фирмата</label>
                    <input type="text" name="companyName" placeholder="Име на фирмата" />
                </div>
                <div className='form-input'>
                    <label htmlFor="companyAddress">Адрес на фирмата</label>
                    <input type="text" name="companyAddress" placeholder="Адрес на фирмата" />
                </div>
                <div className='form-input'>
                    <label htmlFor="companyCity">Град</label>
                    <input type="text" name="companyCity" placeholder="Град" />
                </div>
                <div className='form-input'>
                    <label htmlFor="companyPhone">Телефон</label>
                    <input type="text" name="companyPhone" placeholder="Телефон" />
                </div>
                <div className='form-input'>
                    <label htmlFor="companyEIK">ЕИК</label>
                    <input type="text" name="companyEIK" placeholder="ЕИК" />
                </div>
                <div className='form-input'>
                    <label htmlFor="companyDDSNo">ДДС Номер</label>
                    <input type="text" name="companyDDSNo" placeholder="ДДС" />
                </div>
                <div className='form-input'>
                    <label htmlFor="companyMol">МОЛ</label>
                    <input type="text" name="companyMol" placeholder="МОЛ" />
                </div>
                {error && <p>{error}</p>}
                {loading && <p>Зареждане...</p>}
                <button className='actionButton margin-top' type="submit">Регистрация</button>

            </form>

            <p>Вече имате акаунт</p>
            <button
                className='register'
                onClick={() => navigate('/')}
            >Вход</button>
        </div>
    )
}

export default Register