
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { auth, db, storage } from '../../app/firebase.js';
import { collection, addDoc, updateDoc, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import generateInvoice from '../../functions/generateInvoice.js';

const RenewBusiness = () => {
    const Navigate = useNavigate();

    // get id of business from url and get data from firestore for this business and fill the form with this data 
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1);
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [errorData, setErrorData] = useState(null);

    const [selectedOption, setSelectedOption] = useState(null);

    const options = [
        { months: 3, price: 49, sale: "" },
        { months: 6, price: 245, sale: "Един месец безплатно" },
        { months: 12, price: 490, sale: "Два месеца безплатно" },
    ];

    const handleOptionSelect = (index) => {
        setSelectedOption(index);
    };

    const fetchData = async () => {
        // get signle business from firestore by document id 
        const docRef = doc(db, 'business', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setData({ ...docSnap.data(), id: docSnap.id });
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            setErrorData('Няма такъв бизнес');
            setLoadingData(false);
            return;
        }
        // setData(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
        setLoadingData(false);
    };
    if (loadingData) {
        fetchData();
    }

    const getCompanyData = async () => {
        // get signle business from firestore by document id
        const docRef = doc(db, 'companies', data.companyOwner);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setLoadingData(false);
            return docSnap.data();
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            setErrorData('Няма такъв бизнес');
            setLoadingData(false);
            return;
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // make a purchase and save it to firestore then redirect to my-purchases page
        const activeTo = data.activeTo ? data.activeTo.toDate() : new Date();
        activeTo.setMonth(activeTo.getMonth() + options[selectedOption].months);

        const purchaseData = {
            companyUid: data.companyOwner,
            companyDetails: await getCompanyData(),
            userUid: auth.currentUser.uid,
            price: options[selectedOption].price,
            months: options[selectedOption].months,
            beforeActiveTo: data.activeTo ? data.activeTo.toDate() : '', 
            newActiveTo: activeTo,
            status: 'pending',
            createdAt: new Date(),
            businessName: data.name,
            businessAddress: data.address,
            businessUid: data.id,
        };

        const purchase = {
            companyUid: data.companyOwner,
            companyDetails: await getCompanyData(),
            userUid: auth.currentUser.uid,
            price: options[selectedOption].price,
            months: options[selectedOption].months,
            beforeActiveTo: data.activeTo ? data.activeTo.toDate() : '', 
            newActiveTo: activeTo,
            status: 'pending',
            createdAt: new Date(),
            businessName: data.name,
            businessAddress: data.address,
            businessUid: data.id,
            proformInvoiceUrl: await generateInvoice(purchaseData),
            invoiceUrl: '',
        };

        //generate a invoice document and save it to firestore
        // save invoice to firestore        

        await addDoc(collection(db, 'purchases'), purchase)
            .then((docRef) => {
                console.log('Added purchase with ID: ', docRef.id);
                // navigate to business page with my business with success message
                Navigate('/my-purchases', { state: { message: 'Вашата поръчка е създадена. Моля платете я.' } });
            })
            .catch((error) => {
                console.error('Error adding purchase: ', error);
                setErrorData('Грешка при подновяване на абонамента');
            });
    };

    return (
        <div className='inner-page'>
            <h1>Подновяване на абонамент</h1>
            <div className='inner-page__content'>
                <div className='renew_item'>
                    <img src={data.avatar} alt={data.name} />
                    <div className='renew_item__data'>
                        <h2>{data.name}</h2>
                        <p>Адрес: {data.address}</p>
                        <p>Категория: {data.category}</p>
                    </div>
                </div>
            </div>
            <div>
                <h1>Изберете период за Подновяване</h1>
                <div className="price-table">
                    {options.map((option, index) => (
                        <div
                            key={index}
                            className={`price-option ${selectedOption === index ? 'selected' : ''}`}
                            onClick={() => handleOptionSelect(index)}
                        >
                            <span>{option.sale}</span>
                            <h2>{`${option.months} Месеца`}</h2>
                            <p>{option.price} лв.</p>
                        </div>
                    ))}
                </div>
                {selectedOption !== null && (
                    <div className="selected-option-details">
                        <h2>Вашият избор е:</h2>
                        <p>{`${options[selectedOption].months} Месеца - ${options[selectedOption].price} лв.`}</p>
                        <button className="actionButton" onClick={handleSubmit}>Поднови</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RenewBusiness