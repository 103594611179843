import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// storage 
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD9kDvZUnXFx7b8jZZASurvFpYDixPMDxQ",
  authDomain: "dogmap-b8cfc.firebaseapp.com",
  databaseURL: "https://dogmap-b8cfc-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dogmap-b8cfc",
  storageBucket: "dogmap-b8cfc.appspot.com",
  messagingSenderId: "303600214964",
  appId: "1:303600214964:web:9a6f0436d21f7e6d2808e1",
  measurementId: "G-S4YRGHKGPY"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, app, auth, storage };