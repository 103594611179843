import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { auth } from '../app/firebase.js'

function PrivateRoute({ component: Component, ...rest }) {
    const currentUser = auth.currentUser;

    return (
        currentUser ? <Outlet /> : <Navigate to="/" />
    );
}   

export default PrivateRoute;

